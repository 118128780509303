import { graphql } from 'gatsby';
import React from 'react';
import Layout from '~/components/Layout/Layout';
import Metadata from '~/components/Metadata';
import ArticlePage from '~/components/ArticlePage/ArticlePage';
import { Article } from '../types/types';

const ArticleTemplate: React.FC<ArticleTemplateProps> = ({ data }) => {
  const { article } = data;

  return (
    <Layout>
      <Metadata title={article.title} />
      <ArticlePage article={article} />
    </Layout>
  );
};

export const query = graphql`
  query ArticleQuery($slug: String, $locale: String) {
    article: contentfulArticle(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      ...ArticlePageFragment
    }
  }
`;

interface ArticleTemplateProps {
  data: {
    article: Article;
  };
}

export default ArticleTemplate;
