import React, { useMemo } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Article } from '~/types/types';

import { Section } from '~/styles/Section.styled';
import { Wrapper } from '~/styles/Wrapper.styled';
import ShowOnScroll from '~/components/Generic/ShowOnScroll/ShowOnScroll';

import { StyledArticlePage } from './ArticlePage.styled';
import {
  PageSection,
  ComponentMapExtraData,
} from '~/components/PageSection/PageSection';

const articleSectionsDefaultData: ComponentMapExtraData = {
  'featured-protocols': {
    sectionProps: { gutter: true },
    wrapperProps: { bg: 'gray', gutter: false },
  },
  'text-body': {
    wrapperProps: { width: 'normal' },
  },
};

const ArticlePage: React.FC<ArticlePageProps> = ({ article }) => {
  const { title, publishDate, readTime, heroImage, sections } = article;

  const formattedDate = useMemo(
    () =>
      publishDate &&
      new Date(publishDate).toLocaleDateString(undefined, {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      }),
    [publishDate]
  );

  return (
    <StyledArticlePage>
      <Section>
        <Wrapper width="normal">
          <ShowOnScroll>
            <h1>{title}</h1>
          </ShowOnScroll>
          <ShowOnScroll delay={100}>
            {readTime && (
              <p className="article-page__read-time">{readTime} min read</p>
            )}
            {formattedDate && (
              <p className="article-page__date">{formattedDate}</p>
            )}
          </ShowOnScroll>
        </Wrapper>
      </Section>

      {heroImage && (
        <Section className="article-page__hero">
          <Wrapper rounded={false} gutter={false} width="full">
            <ShowOnScroll delay={200}>
              <GatsbyImage
                image={heroImage.gatsbyImageData}
                alt={heroImage.title}
                objectFit="cover"
              />
            </ShowOnScroll>
          </Wrapper>
        </Section>
      )}

      {sections &&
        sections.map((section, idx) => {
          if (!section?.sectionType) {
            return null;
          }

          const componentData =
            articleSectionsDefaultData[section.sectionType.slug] || {};

          return (
            <PageSection
              key={`${section.slug}_${idx}`}
              section={section}
              {...componentData}
            />
          );
        })}
    </StyledArticlePage>
  );
};

interface ArticlePageProps {
  article: Article;
}

export default ArticlePage;
