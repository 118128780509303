import styled from 'styled-components';
import { pxtorem } from '~/utils/tools';

export const StyledArticlePage = styled.div`
  margin-top: ${pxtorem(40)};

  h1 {
    font-family: var(--secondaryFont);
    font-style: italic;
    font-size: ${pxtorem(34)};
    line-height: 1.1372549;

    @media (min-width: 768px) {
      font-size: ${pxtorem(52)};
    }
  }

  @media (max-width: 767px) {
    h1,
    .article-page__read-time,
    .article-page__date {
      text-align: center;
    }
  }

  .article-page__read-time {
    font-weight: 500;
    font-size: ${pxtorem(12)};
    line-height: 1.1875;
    text-transform: uppercase;
    @media (min-width: 768px) {
      font-size: ${pxtorem(16)};
    }
  }

  .article-page__date {
    font-size: ${pxtorem(12)};
  }

  .article-page__read-time,
  .article-page__date {
    margin: ${pxtorem(8)} 0;
  }

  .article-page__hero {
    margin-top: ${pxtorem(20)};
  }
`;
